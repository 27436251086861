import React from 'react'

interface PDFProps {
	src: string
}

export const PDF = ({src}: PDFProps) => (
	<div className="pdf-embed responsive-embed">
		<iframe src={src} />
	</div>
)