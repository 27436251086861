import React from 'react'
import {graphql, PageProps} from 'gatsby'
import Helmet from 'react-helmet'
import StandardLayout from '../layouts/standard'
import {PDF} from '../components/docs'

const ResumePage = ({data, location}: PageProps<Queries.ResumePageQuery>) => {
	const resumeUrl = data?.resume?.file?.file?.url ?? ''

	return <StandardLayout location={location}>
		<Helmet>
			<body className="resume-page" />
		</Helmet>

		<h1 className="card-style-block-text">Resumé</h1>
		<hr />
		<p>Download <a href={resumeUrl}>PDF</a></p>
		<PDF src={resumeUrl} />
	</StandardLayout>
}

export default ResumePage

export const query = graphql`
	query ResumePage {
		resume: contentfulDocument(type: {eq: "resume"}) {
			file {
				file {
					url
				}
			}
		}
	}
`
